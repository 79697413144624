import React, { useState } from 'react'
import styles from './Hint.module.scss'
import disactive from '../../image/Hint/disactive.svg'

const Hint = ({question, answer}) => {

    const [isActive, setIsActive] = useState(false);


    return (
        <li className={styles.hint}>
            <div className={styles.container}>
                <div className={styles.box} onClick={() => setIsActive(!isActive)}>
                    <div className={styles.hint_box}>
                        <h3 className={styles.title}>{question}</h3>
                    </div>
                    <div className={styles.hint_boxs}>
                        <div className={!isActive ? styles.hint_image_box : styles.hint_active}  onClick={() => setIsActive(!isActive)}>
                            <img className={styles.hint_image} src={disactive} alt='галочка'/>
                        </div>
                        <span className={styles.pulse}></span>
                    </div>
                </div>
                <p className={`${isActive === true ? styles.text : styles.text_no}`}>{answer}</p>
            </div>
        </li>
    )
}

export default Hint