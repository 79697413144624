import React from 'react'
import styles from './Services.module.scss'
import { motion } from "framer-motion"
import image_1 from '../../image/Services/img_1.webp'
import image_2 from '../../image/Services/img_2.webp'
import image_3 from '../../image/Services/img_3.webp'
import image_4 from '../../image/Services/img_4.webp'

const titleAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const cardAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .4}
    } 
}


const Services = ({isOpen}) => {

    return (
        <motion.section 
        className={styles.main}
        id='gruppy' 
        initial='hidden'
        whileInView='visible'
        viewport={{amount: 0.05, once: true}}
        >
        <div className={styles.container}>
        <motion.h2 className={styles.title} variants={titleAnimation}>Вебери свою группу <span>для тренировок</span></motion.h2>
        <motion.ul className={styles.list}>
            <motion.li 
                className={styles.cell}
                variants={cardAnimation}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.01, once: true}}
                >
                <div className={styles.cell_box}>
                    <img className={styles.cell_image} src={image_1} alt='стружка'/>
                </div>
                <div className={styles.cell_info}>
                    <h3 className={styles.cell_subtitle}>Группа ОФП с элементами борьбы и гимнастики</h3>
                    <p className={styles.cell_years}>(5-7 лет)</p>
                    <p className={styles.cell_days}>ВТ, ЧТ. 19:00-20:00</p>
                    <p className={styles.cell_text}>Общая физподготовка, развитие гибкости, координации, ловкости</p>
                    <button className={styles.cell_button} type='button' onClick={isOpen}>записаться в группу <span className={styles.glare}></span></button>
                </div>
            </motion.li>
            <motion.li 
                className={styles.cell}
                variants={cardAnimation}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.01, once: true}}
                >
                <div className={styles.cell_box}>
                    <img className={styles.cell_image} src={image_2} alt='стружка'/>
                </div>
                <div className={styles.cell_info}>
                    <h3 className={styles.cell_subtitle}>Группа начальной подготовки</h3>
                    <p className={styles.cell_years}>(7-8 лет)</p>
                    <p className={styles.cell_days}>ПН, СР, ПТ 15.00 -16.30</p>
                    <p className={styles.cell_text}>Общая физподготовка, развитие гибкости, координации, ловкости. Первичные борцовские навыки в партере и стойке</p>
                    <button className={styles.cell_button} type='button' onClick={isOpen}>записаться в группу <span className={styles.glare}></span></button>
                </div>
            </motion.li>
            <motion.li 
                className={styles.cell}
                variants={cardAnimation}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.01, once: true}}
                >
                <div className={styles.cell_box}>
                    <img className={styles.cell_image} src={image_3} alt='стружка'/>
                </div>
                <div className={styles.cell_info}>
                    <h3 className={styles.cell_subtitle}>Группа учебно-тренировочная</h3>
                    <p className={styles.cell_years}>(8-10 лет)</p>
                    <p className={styles.cell_days}>ПН, СР, ПТ 16.30 -18.00</p>
                    <p className={styles.cell_text}>Отработка навыков борьбы в партере и стойке, акробатика, упражнения на выносливость</p>
                    <button className={styles.cell_button} type='button' onClick={isOpen}>записаться в группу <span className={styles.glare}></span></button>
                </div>
            </motion.li>
            <motion.li 
                className={styles.cell}
                variants={cardAnimation}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.01, once: true}}
                >
                <div className={styles.cell_box}>
                    <img className={styles.cell_image} src={image_4} alt='стружка'/>
                </div>
                <div className={styles.cell_info}>
                    <h3 className={styles.cell_subtitle}>Группа учебно-тренировочная</h3>
                    <p className={styles.cell_years}>(от 11 лет и старше)</p>
                    <p className={styles.cell_days}>ПН, СР, ПТ 18.00 -19.30</p>
                    <p className={styles.cell_text}>Совершенствование техники борьбы в стойке и партере, ОФП и СФП, упражнения на выносливость</p>
                    <button className={styles.cell_button} type='button' onClick={isOpen}>записаться в группу <span className={styles.glare}></span></button>
                </div>
            </motion.li>
        </motion.ul>
        </div>   
    </motion.section>
    )
}

export default Services