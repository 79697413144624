import React from 'react'
import styles from './Feedback.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import {  Pagination, Autoplay} from "swiper"
import "./Feedback.css"
import 'swiper/scss'
import "swiper/scss/pagination"
import "swiper/scss/navigation"
import img from '../../image/Feedback/img.webp'

const Feedback = () => {


    return (
        <section className={styles.main} id='otzyvy'>
            <div className={styles.box}>
                <h2 className={styles.title}>Отзывы родителей<span> наших&#160;учеников</span></h2>
                    <div className={styles.slider}>
                        <Swiper
                                spaceBetween={10}
                                slidesPerView={1.3}
                                centeredSlides={true}
                                loop={true}
                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: false,
                                }}
                                pagination={{
                                    type: 'fraction'
                                }}
                                breakpoints={{

                                    1400: {
                                        slidesPerView: 1.5,
                                        spaceBetween: 5,
                                    },

                                    768: {
                                        slidesPerView: 1.2,
                                        spaceBetween: 5,
                                    },

                                    425: {
                                        slidesPerView: 1.1,
                                        spaceBetween: 5,
                                    },

                                    320: {
                                        slidesPerView: 1.05,
                                        spaceBetween: 5,
                                    },
                                }}
                                modules={[Pagination, Autoplay]}
                                className="mySwiper"
                                >
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Елена</p>
                                        <p className={styles.comment}>04.10.2023</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Сын ходит к тренеру второй год. Тренировки обожает. Я считаю нам очень повезло с тренером, Сергей Иванович умеет находить подход к детям. Выстаивает правильные взаимоотношения в коллективе, очень нравится отношение старших к младшим. И главное сын теперь бежит в зал, с удовольствием изучает новые техники и слушает тренера. В этом году организовали поездку в спорт лагерь. Дети вернулись довольными, закаленными, отдохнувшими.</p>                              
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Людмила Б.</p>
                                        <p className={styles.comment}>04.10.2023</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Отличный клуб Ребёнок ходит меньше месяца, научился залезать на канат и многое другое Добрая, спокойная, уважительная обстановка. Сергей Иванович заинтересовывает детей,мотивирует, дети в восторге и на занятия бегут с удовольствием.</p>                              
                                    </div>
                                </div>  
                            </SwiperSlide>
                            <SwiperSlide>
                            <div className={styles.cell}>
                                <div className={styles.item}>
                                    <p className={styles.subtitle}>Сергей Владимирович</p>
                                    <p className={styles.comment}>17.08.2021</p>
                                    <img className={styles.image} src={img} alt = 'звездочки'/>
                                    <p className={styles.comment}>Комментарий</p>
                                    <p className={styles.text}>Сергей Иванович - Тренер ТОП уровня, в свое время воспитал чемпиона мира по самбо и нескольких ребят схожих по классу. В нем есть самое главное: он постоянно наблюдает за тем как тренируются ученики, анализирует и любит подмечать детали в технике. Также у него в зале дисциплина и здоровая атмосфера; хорошее отношение друг к другу, он и сам с чувством юмора)
                                    Я был во многих клубах единоборств Москвы, и скажу вам этот тренер - находка. Тренировки с ним в группе, по сути, как персональные у других.</p>                              
                                </div>
                            </div>      
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.cell}>    
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Алена П.</p>
                                        <p className={styles.comment}>17.01.2022</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Сергей Иванович - отличный тренер, настоящий профессионал своего дела. Сын всего приходит с занятий очень довольный.
                                        Несмотря на то, что занятия проходят в игровой форме тренер поддерживает дисциплину.</p>                              
                                    </div>
                                </div>      
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.cell}>    
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Маргарита</p>
                                        <p className={styles.comment}>17.08.2021</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Сергей Иванович Фролов - замечательный тренер. Проводит тренировки с энтузиазмом и знанием дела. Отлично ладит с детьми, понимает, что к каждому ребенку нужен персональный подход. После обязательной части даёт ещё 30 минут на веселые игры. Дети с удовольствием ходят на занятия.</p>                              
                                    </div>
                                </div>      
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.cell}>    
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Olga Krylova</p>
                                        <p className={styles.comment}>29.10.2020</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Замечательный клуб. Мои дети 12 и 6 лет ходят на тренировки в этот клуб с прошлого года. Выходят с тренировки довольные. Тренер - это просто подарок. Давно искали место, но либо местоположение не устраивало,либо подход. Тут же, все на высоте. Спасибо!</p>                              
                                    </div>
                                </div>      
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Вандышев Михаил</p>
                                        <p className={styles.comment}>8.09.2022</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Ходим полгода в клуб, сыну очень нравится, всегда рвётся быстрее на тренировки! Тренер находит подход к каждому ребёнку. Сами тренировки интенсивные, дети отлично выматываются</p>                              
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
        </section>
    )
}

export default Feedback