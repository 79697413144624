import React from 'react'
import styles from './InitialPage.module.scss'


const InitialPage = ({isOpen}) => {
    return (
        <section className={styles.main}>
            <div className={styles.overlay}>
                <div className={styles.container}>
                    <p className={styles.text}>ДОБРО ПОЖАЛОВАТЬ</p>
                    <h1 className={styles.title}>Школа борьбы дзюдо&#160;и&#160;самбо</h1>
                    <ul className={styles.list}>
                        <li className={styles.item}>
                            <p className={styles.check}>&#10003;</p>
                            <p className={styles.item_text}>Группы для любого возраста и подготовки</p>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.check}>&#10003;</p>
                            <p className={styles.item_text}>До 20 человек в каждой возрастной группе</p>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.check}>&#10003;</p>
                            <p className={styles.item_text}>Проведение внутренних и выездных турниров</p>
                        </li>
                    </ul>
                    <div className={styles.box_button}>
                        <button className={styles.button} type='button' onClick={isOpen}>записаться на пробное занятие <span className={styles.glare}></span></button>
                    </div>
                </div>
            </div> 
        </section>
    )
}

export default InitialPage