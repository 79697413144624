import React, {useState} from 'react'
import header from './Header.module.scss'
import logo from '../../image/Header/logo.png'
import log from '../../image/Header/44.png'
import ButtonBox from '../ButtonBox/ButtonBox'

const Header = ({isOpen}) => {

    const [visible, setVisible] = useState(false);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 10){
            setVisible(true)
        } 
        else if (scrolled <= 10){
            setVisible(false)
        }
    };

    window.addEventListener('scroll', toggleVisible);

    return (
        <header className={`${header.header} ${visible && header.header_scroll}`}>
        <div className={header.box}>
                <div className={header.info}>
                    <div className={header.box_logo}>
                        <a className={header.logo_link} href='initial'>
                            <img className={header.logo} src={logo} alt = 'логотип'/>
                            <img className={header.logo_min} src={log} alt = 'логотип'/>
                        </a>
                        <div className={header.contacts}>
                            <div className={header.box_social}>
                                <ButtonBox/>
                            </div>
                            <div className={header.box_phone}>
                                <a className={header.phone}  href="tel:+79857313363" target='_blank' rel='noopener noreferrer'>+7 985 <span>731-33-63</span></a>
                            </div>
                        </div>
                        <button className={header.button} type='button' onClick={isOpen} aria-label='Открыть меню'/>
                    </div>
                    <nav className={header.nav}>
                        <a className={header.link} href='#gruppy'>Группы</a>
                        <div className={header.line}></div>
                        <a className={header.link} href='#preimushchestva'>Преимущества</a>
                        <div className={header.line}></div>
                        <a className={header.link} href='#otzyvy'>Отзывы</a>
                        <div className={header.line}></div>
                        <a className={header.link} href='#voprosy-i-otvety'>Вопросы и ответы</a>
                        <div className={header.line}></div>
                        <a className={header.link} href='#kontakty'>Контакты</a>
                    </nav>
                </div>
        </div>
    </header>    
    );
};

export default Header