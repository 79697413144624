import React from 'react'
import { motion } from "framer-motion"
import styles from './AboutUs.module.scss'
import image from '../../image/AboutUs/img.webp'

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .8 }
    }
}

const textAnimation = {
    hidden: {
        x: 500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .6 }
    }
}

const AboutUs = ({isOpen}) => {

    return (
        <motion.section 
            className={styles.main} 
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            id='AboutUs'             
            >
            <div className={styles.container}>
                <motion.img className={styles.image} src={image} alt='металл' variants={imageAnimation}/>
                <motion.div className={styles.box_info} variants={textAnimation}>
                        <h2 className={styles.title}>О школе борьбы <span>СК&#160;Патриот</span></h2>
                        <p className={styles.text}>&#8195;Спортивный клуб Патриот обладает многолетней историей и большим опытом воспитания юных спортсменов. Старшим тренером нашего клуба является Сергей Иванович Фролов, который занимается обучением дзюдо и самбо с 1983 года.</p>
                        <p className={styles.text}>Воспитанники Сергея Ивановича неоднократно становились призерами и победителями всероссийских соревнований, чемпионами Москвы и России по дзюдо и самбо, несколько спортсменов доросли до уровня мастера спорта. Один из его учеников является неоднократный чемпионом России, чемпионом Европы и Мира.</p>
                        <p className={styles.text}>Мы уверены, что способности есть у всех. Наши тренеры находят подход к каждому ребенку и помогают ему достичь первых результатов, с учетом его особенностей. Спортивный клуб Патриот входит в федерацию Дзюдо Москвы, наши ученики принимают участие во всех региональных и всероссийских соревнованиях.</p>
                        <div className={styles.box_link}>
                            <button className={styles.link} type='button' onClick={isOpen}>записаться на пробное занятие <span className={styles.glare}></span></button>
                        </div>
                    </motion.div>
            </div>
        </motion.section>
    )
}

export default AboutUs