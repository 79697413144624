import React from 'react'
import styles from './Main.module.scss'
import InitialPage from '../../components/initialPage/InitialPage'
import Confidence from '../../components/Confidence/Confidence'
import Feedback from '../../components/Feedback/Feedback'
import Services from '../../components/Services/Services'
import SliderAdvantages from '../../components/SliderAdvantages/SliderAdvantages'
import Map from '../../components/Map/Map'
import VideoСollection from '../../components/VideoСollection/VideoСollection'
import PhoneCoffee from '../../components/PhoneCoffee/PhoneCoffee'
import Questions from '../../components/Questions/Questions'
import Callback from '../../components/Callback/Callback'
import AboutUs from '../../components/AboutUs/AboutUs'

const Main = ({isOpen, popupOpen}) => {

    return (
        <section className={styles.main}>
            <InitialPage
                isOpen={isOpen}
            />
            <SliderAdvantages/>
            <Services
                isOpen={isOpen}
            />
            <AboutUs/>
            <Confidence/>
            <VideoСollection/>
            <PhoneCoffee
                isOpen={isOpen}
            />
            <Feedback/>
            <Questions/>
            <Callback
                popupOpen={popupOpen}
            />
            <Map/>
        </section>
    )
}

export default Main